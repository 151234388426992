
import React, { useEffect, useState } from "react";
import "../css/Layout.css";
import { Link, useLocation } from "react-router-dom";

const DeviceLayout = ({ children }) => {
  const location = useLocation();
  const [description, setDescription] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [newDescription, setNewDescription] = useState("");

  const fetchData = async () => {
    const id = getDeviceIdFromUrl();
    if (id) {
      try {
        
        const response = await fetch(`/api/device/desc/${id}`);
        const data = await response.json();

        setDescription(data.description || "N/A");
        setNewDescription(data.description);
      } catch (error) {
        console.error("Error fetching device data:", error);
      }
    } else {
      console.error("Device ID not found in the URL.");
    }
  };

  const getDeviceIdFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("id");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setNewDescription(e.target.value);
  };

  const handleUpdateDescription = async () => {
    const id = getDeviceIdFromUrl();
    try {
      const response = await fetch("/api/update_user_description", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_id: id,
          user_description: newDescription,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setDescription(newDescription);
        setIsEditing(false);
        alert(data.message);
      } else {
        const errorData = await response.json();
        alert(errorData.error);
      }
    } catch (error) {
      console.error("Error updating description:", error);
    }
  };

  const searchParams = new URLSearchParams(location.search);
  const currentId = searchParams.get("id");

  return (
    <div className="frame">
      <div className="topdevice">
        <div className="frame-buttons">
          <Link to={`/device/data?id=${currentId}`} className="frame-button">
            Info
          </Link>
          <Link to={`/device/detail?id=${currentId}`} className="frame-button">
            Detail
          </Link>
          <Link to={`/device/history?id=${currentId}`} className="frame-button">
            History
          </Link>
          <Link to={`/device/events?id=${currentId}`} className="frame-button">
            Events
          </Link>
        </div>
        <div className="frame-description">
        {isEditing ? (
          <div>
            <input
              type="text"
              value={newDescription}
              onChange={handleInputChange}
              placeholder="Enter new description"
            />
            <button onClick={handleUpdateDescription}>Save</button>
            <button onClick={() => setIsEditing(false)}>Cancel</button>
          </div>
        ) : (
          <div>
            <div className="Title" id="Title" onClick={handleEditClick}>
              {description}
            </div>
          </div>
        )}
        </div>
      </div>
      {children}
    </div>
  );
};

export default DeviceLayout;